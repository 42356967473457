export const IDENTIFICATION_PDF_CONTENTS = [
  {
    lang: 'en',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
  {
    lang: 'cn',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
  {
    lang: 'tw',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
  {
    lang: 'ko',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
  {
    lang: 'ja',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
  {
    lang: 'pt',
    contents: [
      {
        id: 1,
        label: 'identificationStep10Agreement1',
        value: '/pdfs/identification/en/1_Summary_Order_Execution_Policy.pdf',
      },
      {
        id: 2,
        label: 'identificationStep10Agreement2',
        value: '/pdfs/identification/en/2_Risk_Disclosure_Notice.pdf',
      },
      {
        id: 3,
        label: 'identificationStep10Agreement3',
        value: '/pdfs/identification/en/3_Website_And_Platform_Privacy_Policy.pdf',
      },
      {
        id: 4,
        label: 'identificationStep10Agreement4',
        value: '/pdfs/identification/en/4_Key_Information_Document.pdf',
      },
    ],
  },
];
